<template>
  <div class="user-interface admin">
    <div class="sidebar noselect col-mobile-displaynone">
      <div class="logo">
        <img src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png" alt="Logo" />
        <h3>{{ $t('admin.title') }}</h3>
      </div>
      <div class="list col-pc-12">
        <ul
          class="v-step-sidebar"
          v-if="
            isCivilianMenuOpen === false &&
              isPoliceMenuOpen === false &&
              isLegalMenuOpen === false &&
              isPluginsMenuOpen === false
          "
        >
          <li>
            <router-link :to="{ name: 'admin-general' }">
              <div class="col-pc-3">
                <i class="fas fa-user"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('admin.sidebar.general') }}</span>
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-users' }">
              <div class="col-pc-3">
                <i class="fas fa-users"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('admin.sidebar.users') }}</span>
              </div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'admin-access-codes' }">
              <div class="col-pc-3">
                <i class="fas fa-lock"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('admin.sidebar.access-codes') }}</span>
              </div>
            </router-link>
          </li>
          <li>
            <a href="#" @click="isCivilianMenuOpen = true">
              <div class="col-pc-3">
                <i class="fas fa-male"></i>
              </div>
              <div class="col-pc-6 uk-text-left">
                <span>{{ $t('admin.sidebar.civilian') }}</span>
              </div>
              <div class="col-pc-3 uk-text-right">
                <i class="lc lc-angle-right"></i>
              </div>
            </a>
          </li>
          <li>
            <a href="#" @click="isPoliceMenuOpen = true">
              <div class="col-pc-3">
                <i class="lc lc-policeman"></i>
              </div>
              <div class="col-pc-6 uk-text-left">
                <span>{{ $t('admin.sidebar.police') }}</span>
              </div>
              <div class="col-pc-3 uk-text-right">
                <i class="lc lc-angle-right"></i>
              </div>
            </a>
          </li>
          <!-- TODO: Bring back this code when Penal Code is working -->
          <!-- <li>
            <a href="#" @click="isLegalMenuOpen = true">
              <div class="col-pc-3">
                <i class="fa fa-balance-scale"></i>
              </div>
              <div class="col-pc-6 uk-text-left">
                <span>{{ $t('admin.sidebar.legal') }}</span>
              </div>
              <div class="col-pc-3 uk-text-right">
                <i class="lc lc-angle-right"></i>
              </div>
            </a>
          </li> -->
          <li>
            <a href="#" @click="isPluginsMenuOpen = true">
              <div class="col-pc-3">
                <i class="fas fa-puzzle-piece"></i>
              </div>
              <div class="col-pc-6 uk-text-left">
                <span>{{ $t('admin.sidebar.plugins') }}</span>
              </div>
              <div class="col-pc-3 uk-text-right">
                <i class="lc lc-angle-right"></i>
              </div>
            </a>
          </li>
        </ul>
        <div class="plugins-menu" v-if="isPluginsMenuOpen">
          <ul class="return-main-menu">
            <li>
              <a href="#" @click="isPluginsMenuOpen = false">
                <div class="col-all-3">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div class="col-all-9">
                  <span>{{ $t('admin.sidebar.main-menu') }}</span>
                </div>
              </a>
            </li>
          </ul>
          <div class="separator"></div>
          <div class="subtitle">
            <h2>{{ $t('admin.sidebar.plugins') }}</h2>
          </div>
          <ul>
            <li>
              <router-link :to="{ name: 'admin-plugins-fivem' }">
                <div class="col-all-3">
                  <svg viewBox="0 0 342 390.5" style="height: 20px; vertical-align: bottom;">
                    <g transform="matrix(1,0,0,-1,-124.2,606.4)">
                      <path
                        style="fill: #fff;"
                        d="m 125.8,215.9 85.1,0 c 1.9,0 7.4,18.3 16.7,54.9 32.3,112.4 50.9,178.1 55.7,197.2 l -54.9,54.1 -1.6,0 C 219.4,499 185.2,397.2 124.2,216.7 l 1.6,-0.8 z m 163.8,275.2 0.8,0 c 1.1,4.5 1.6,7.2 1.6,8 l 0,1.6 c -15.9,16.7 -33.7,34.5 -53.3,53.3 -2.1,-3.2 -3.2,-5.8 -3.2,-8 l 0,-0.8 c 19.9,-20.5 37.9,-38.5 54.1,-54.1 z M 393,429 l 0.8,0 c -10.9,34.5 -17.5,52.2 -19.9,53.3 L 254.6,600.8 c -1.3,0 -4.2,-8.5 -8.7,-25.4 L 393,429 Z m -22.3,65.3 0.8,0 c -24.4,74 -37.4,111.3 -39,112.1 l -73.2,0 0,-0.8 C 286.4,578 323.5,540.9 370.7,494.3 Z m 43.8,-128.1 0.8,0 c -2.7,13 -9,23.1 -19.1,30.2 -31,31.8 -62,62.8 -93,93 l -0.8,0 c 1.9,-10.9 6.1,-19.1 12.7,-24.7 l 99.4,-98.5 z m 50.1,-150.3 1.6,0.8 c -22.8,67.9 -35,102.9 -36.6,105 l -109.8,108.9 0,-0.8 c 4.2,-16.7 24.7,-88 61.2,-213.9 l 83.6,0 z"
                        ne="0.19642415039789274"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div class="col-all-9">
                  <span>{{ $t('admin.sidebar.fivem') }}</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="police-menu" v-if="isPoliceMenuOpen">
          <ul class="return-main-menu">
            <li>
              <a href="#" @click="isPoliceMenuOpen = false">
                <div class="col-all-3">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div class="col-all-9">
                  <span>{{ $t('admin.sidebar.main-menu') }}</span>
                </div>
              </a>
            </li>
          </ul>
          <div class="separator"></div>
          <div class="subtitle">
            <h2>{{ $t('admin.sidebar.police') }}</h2>
          </div>
          <ul>
            <li>
              <router-link :to="{ name: 'admin-police-settings' }">
                <div class="col-all-3 link-icon">
                  <i class="fas fa-cog"></i>
                </div>
                <div class="col-all-9 link-text">
                  <span>{{ $t('admin.sidebar.settings') }}</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'admin-status-codes' }">
                <div class="col-all-3">
                  <i class="fas fa-broadcast-tower"></i>
                </div>
                <div class="col-all-9">
                  <span>{{ $t('admin.sidebar.status-codes') }}</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="civilian-menu" v-if="isCivilianMenuOpen">
          <ul class="return-main-menu">
            <li>
              <a href="#" @click="isCivilianMenuOpen = false">
                <div class="col-all-3">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div class="col-all-9">
                  <span>{{ $t('admin.sidebar.main-menu') }}</span>
                </div>
              </a>
            </li>
          </ul>
          <div class="separator"></div>
          <div class="subtitle">
            <h2>{{ $t('admin.sidebar.civilian') }}</h2>
          </div>
          <ul>
            <li>
              <router-link :to="{ name: 'admin-civilian-settings' }">
                <div class="col-all-3 link-icon">
                  <i class="fas fa-cog"></i>
                </div>
                <div class="col-all-9 link-text">
                  <span>{{ $t('admin.sidebar.settings') }}</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'admin-driver-licence' }">
                <div class="col-all-3 link-icon">
                  <i class="fas fa-address-card"></i>
                </div>
                <div class="col-all-9 link-text">
                  <span>{{ $t('admin.sidebar.driver-licence') }}</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="legal-menu" v-if="isLegalMenuOpen">
          <ul class="return-main-menu">
            <li>
              <a href="#" @click="isLegalMenuOpen = false">
                <div class="col-all-3">
                  <i class="fas fa-long-arrow-alt-left"></i>
                </div>
                <div class="col-all-9">
                  <span>{{ $t('admin.sidebar.main-menu') }}</span>
                </div>
              </a>
            </li>
          </ul>
          <div class="separator"></div>
          <div class="subtitle">
            <h2>{{ $t('admin.sidebar.legal') }}</h2>
          </div>
          <ul>
            <li>
              <router-link :to="{ name: 'admin-penal-code' }">
                <div class="col-all-3 link-icon">
                  <i class="fas fa-gavel"></i>
                </div>
                <div class="col-all-9 link-text">
                  <span>{{ $t('admin.sidebar.penal-code') }}</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="separator"
        v-if="
          isCivilianMenuOpen === false &&
            isPoliceMenuOpen === false &&
            isPluginsMenuOpen === false &&
            isLegalMenuOpen === false
        "
      ></div>
      <div
        class="return"
        v-if="
          isCivilianMenuOpen === false &&
            isPoliceMenuOpen === false &&
            isPluginsMenuOpen === false &&
            isLegalMenuOpen === false
        "
      >
        <ul>
          <li>
            <router-link :to="{ name: 'roles' }">
              <div class="col-pc-3">
                <i class="fas fa-long-arrow-alt-left"></i>
              </div>
              <div class="col-pc-9">
                <span>{{ $t('profile.sidebar.return') }}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-content col-pc-11 col-mobile-12">
      <div
        class="mobile-topbar noselect col-pc-displaynone-important col-mobile-12 col-f"
        :class="{ active: isMobileMenuOpen === true }"
      >
        <div class="left col-mobile-3">
          <a
            href="#"
            class="menu-icon"
            :class="{ active: isMobileMenuOpen }"
            @click="
              isMobileMenuOpen = !isMobileMenuOpen
              isMobileUserDropdownOpen = false
              isMobilePluginsMenuOpen = false
              isMobilePoliceMenuOpen = false
              isMobileCivilianMenuOpen = false
              isMobileLegalMenuOpen = false
            "
          >
            <i class="fas fa-bars"></i>
          </a>
        </div>
        <div class="center col-mobile-6">
          <div class="logo">
            <img
              src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png"
              alt="Logo"
              width="70px"
              height="70px"
            />
          </div>
        </div>
        <div class="right col-mobile-3">
          <a
            href="#"
            class="image"
            :class="{ active: isMobileUserDropdownOpen }"
            @click="
              isMobileUserDropdownOpen = !isMobileUserDropdownOpen
              isMobileMenuOpen = false
            "
          >
            <VueGravatar v-if="!user.image" :size="120" :email="user.email" default-img="retro" />
            <img v-if="user.image" :src="user.image" alt="user image" />
          </a>
        </div>
      </div>
      <div
        class="mobile-dropdowns-container col-mobile-12 col-pc-displaynone"
        v-if="isMobileMenuOpen || isMobileUserDropdownOpen"
      >
        <div class="menu-dropdown col-mobile-12" :class="{ active: isMobileMenuOpen }">
          <div class="list">
            <ul
              v-if="
                !isMobileCivilianMenuOpen &&
                  !isMobilePoliceMenuOpen &&
                  !isMobilePluginsMenuOpen &&
                  !isMobileLegalMenuOpen
              "
            >
              <li>
                <router-link :to="{ name: 'admin-general' }">
                  <div class="col-all-2 link-icon">
                    <i class="fas fa-user"></i>
                  </div>
                  <div class="col-all-10 link-text">
                    <span>{{ $t('admin.sidebar.general') }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'admin-users' }">
                  <div class="col-all-2 link-icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="col-all-10 link-text">
                    <span>{{ $t('admin.sidebar.users') }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'admin-access-codes' }">
                  <div class="col-all-2 link-icon">
                    <i class="fas fa-lock"></i>
                  </div>
                  <div class="col-all-10 link-text">
                    <span>{{ $t('admin.sidebar.access-codes') }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <a href="#" @click="isMobileCivilianMenuOpen = !isMobileCivilianMenuOpen">
                  <div class="col-all-2 link-icon">
                    <i class="fas fa-male"></i>
                  </div>
                  <div class="col-all-8 link-text">
                    <span>{{ $t('admin.sidebar.civilian') }}</span>
                  </div>
                  <div class="col-all-2 dropdown-icon">
                    <i class="lc lc-angle-right"></i>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" @click="isMobilePoliceMenuOpen = !isMobilePoliceMenuOpen">
                  <div class="col-all-2 link-icon">
                    <i class="lc lc-policeman"></i>
                  </div>
                  <div class="col-all-8 link-text">
                    <span>{{ $t('admin.sidebar.police') }}</span>
                  </div>
                  <div class="col-all-2 dropdown-icon">
                    <i class="lc lc-angle-right"></i>
                  </div>
                </a>
              </li>
              <!-- TODO: Bring back this code when penal code is working -->
              <!-- <li>
                <a href="#" @click="isMobileLegalMenuOpen = !isMobileLegalMenuOpen">
                  <div class="col-all-2 link-icon">
                    <i class="fas fa-balance-scale"></i>
                  </div>
                  <div class="col-all-8 link-text">
                    <span>{{ $t('admin.sidebar.legal') }}</span>
                  </div>
                  <div class="col-all-2 dropdown-icon">
                    <i class="lc lc-angle-right"></i>
                  </div>
                </a>
              </li> -->
              <li>
                <a href="#" @click="isMobilePluginsMenuOpen = !isMobilePluginsMenuOpen">
                  <div class="col-all-2 link-icon">
                    <i class="fas fa-puzzle-piece"></i>
                  </div>
                  <div class="col-all-8 link-text">
                    <span>{{ $t('admin.sidebar.plugins') }}</span>
                  </div>
                  <div class="col-all-2 dropdown-icon">
                    <i class="lc lc-angle-right"></i>
                  </div>
                </a>
              </li>
            </ul>
            <div class="plugins-menu" v-if="isMobilePluginsMenuOpen">
              <ul class="return-main-menu">
                <li>
                  <a href="#" @click="isMobilePluginsMenuOpen = false">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-long-arrow-alt-left"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.main-menu') }}</span>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="separator"></div>
              <div class="subtitle">
                <h2>{{ $t('admin.sidebar.plugins') }}</h2>
              </div>
              <ul>
                <li>
                  <router-link :to="{ name: 'admin-plugins-fivem' }">
                    <div class="col-all-2 link-icon">
                      <svg viewBox="0 0 342 390.5" style="height: 30px; vertical-align: bottom;">
                        <g transform="matrix(1,0,0,-1,-124.2,606.4)">
                          <path
                            style="fill: #fff;"
                            d="m 125.8,215.9 85.1,0 c 1.9,0 7.4,18.3 16.7,54.9 32.3,112.4 50.9,178.1 55.7,197.2 l -54.9,54.1 -1.6,0 C 219.4,499 185.2,397.2 124.2,216.7 l 1.6,-0.8 z m 163.8,275.2 0.8,0 c 1.1,4.5 1.6,7.2 1.6,8 l 0,1.6 c -15.9,16.7 -33.7,34.5 -53.3,53.3 -2.1,-3.2 -3.2,-5.8 -3.2,-8 l 0,-0.8 c 19.9,-20.5 37.9,-38.5 54.1,-54.1 z M 393,429 l 0.8,0 c -10.9,34.5 -17.5,52.2 -19.9,53.3 L 254.6,600.8 c -1.3,0 -4.2,-8.5 -8.7,-25.4 L 393,429 Z m -22.3,65.3 0.8,0 c -24.4,74 -37.4,111.3 -39,112.1 l -73.2,0 0,-0.8 C 286.4,578 323.5,540.9 370.7,494.3 Z m 43.8,-128.1 0.8,0 c -2.7,13 -9,23.1 -19.1,30.2 -31,31.8 -62,62.8 -93,93 l -0.8,0 c 1.9,-10.9 6.1,-19.1 12.7,-24.7 l 99.4,-98.5 z m 50.1,-150.3 1.6,0.8 c -22.8,67.9 -35,102.9 -36.6,105 l -109.8,108.9 0,-0.8 c 4.2,-16.7 24.7,-88 61.2,-213.9 l 83.6,0 z"
                            ne="0.19642415039789274"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.fivem') }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="police-menu" v-if="isMobilePoliceMenuOpen">
              <ul class="return-main-menu">
                <li>
                  <a href="#" @click="isMobilePoliceMenuOpen = false">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-long-arrow-alt-left"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.main-menu') }}</span>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="separator"></div>
              <div class="subtitle">
                <h2>{{ $t('admin.sidebar.police') }}</h2>
              </div>
              <ul>
                <li>
                  <router-link :to="{ name: 'admin-police-settings' }">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-cog"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.settings') }}</span>
                    </div>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'admin-status-codes' }">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-broadcast-tower"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.status-codes') }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="civilian-menu" v-if="isMobileCivilianMenuOpen">
              <ul class="return-main-menu">
                <li>
                  <a href="#" @click="isMobileCivilianMenuOpen = false">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-long-arrow-alt-left"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.main-menu') }}</span>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="separator"></div>
              <div class="subtitle">
                <h2>{{ $t('admin.sidebar.civilian') }}</h2>
              </div>
              <ul>
                <li>
                  <router-link :to="{ name: 'admin-civilian-settings' }">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-cog"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.settings') }}</span>
                    </div>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'admin-driver-licence' }">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-address-card"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.driver-licence') }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="legal-menu" v-if="isMobileLegalMenuOpen">
              <ul class="return-main-menu">
                <li>
                  <a href="#" @click="isMobileLegalMenuOpen = false">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-long-arrow-alt-left"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.main-menu') }}</span>
                    </div>
                  </a>
                </li>
              </ul>
              <div class="separator"></div>
              <div class="subtitle">
                <h2>{{ $t('admin.sidebar.legal-system') }}</h2>
              </div>
              <ul>
                <li>
                  <router-link :to="{ name: 'admin-penal-code' }">
                    <div class="col-all-2 link-icon">
                      <i class="fas fa-gavel"></i>
                    </div>
                    <div class="col-all-10 link-text">
                      <span>{{ $t('admin.sidebar.penal-code') }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <ul
              class="return-mobile"
              v-if="
                !isMobilePoliceMenuOpen &&
                  !isMobileCivilianMenuOpen &&
                  !isMobilePluginsMenuOpen &&
                  !isMobileLegalMenuOpen
              "
            >
              <li>
                <router-link :to="{ name: 'communities' }">
                  <div class="col-all-2 link-icon">
                    <i class="fas fa-long-arrow-alt-left"></i>
                  </div>
                  <div class="col-all-10 link-text">
                    <span>{{ $t('admin.sidebar.return') }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
            <div class="separator w-80"></div>
            <ul class="language-selector">
              <div class="subtitle">
                <h2>{{ $t('profile.topbar.settings') }}</h2>
              </div>
              <div class="col-all-3">
                <LanguageSelector class="side-right"></LanguageSelector>
              </div>
            </ul>
          </div>
        </div>
        <div class="user-dropdown col-mobile-12" :class="{ active: isMobileUserDropdownOpen }">
          <div class="list">
            <ul>
              <li>
                <router-link :to="{ name: 'profile-security' }">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-lock"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.topbar.change-password') }}</span>
                  </div>
                </router-link>
              </li>
              <li class="logout-mobile">
                <a @click="logout">
                  <div class="col-all-3 link-icon">
                    <i class="fas fa-sign-out-alt"></i>
                  </div>
                  <div class="col-all-12 link-text">
                    <span>{{ $t('profile.topbar.logout') }}</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="topbar col-mobile-displaynone-important col-pc-12">
        <div class="left col-pc-6">
          <LanguageSelector class="side-right"></LanguageSelector>
        </div>
        <div class="right col-pc-6">
          <div
            class="user"
            :class="{ active: isUserDropdownActive }"
            @click="isUserDropdownActive = !isUserDropdownActive"
            v-click-outside="
              () => {
                isUserDropdownActive = false
              }
            "
          >
            <span>{{ user.email }}</span>
            <div class="image">
              <VueGravatar v-if="!user.image" :size="120" :email="user.email" default-img="retro" />
              <img v-if="user.image" :src="user.image" alt="user image" />
            </div>
            <transition name="menu-popover">
              <div class="user-dropdown" v-if="isUserDropdownActive">
                <!-- :class="{ active: isUserDropdownActive }"> -->
                <ul>
                  <router-link :to="{ name: 'profile-security' }">
                    <li>
                      <i class="fas fa-lock"></i>
                      <span>{{ $t('profile.topbar.change-password') }}</span>
                    </li>
                  </router-link>
                  <a @click="logout">
                    <li>
                      <i class="fas fa-sign-out-alt"></i>
                      <span>{{ $t('profile.topbar.logout') }}</span>
                    </li>
                  </a>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="content col-pc-12 col-mobile-11">
        <router-view></router-view>
      </div>

      <v-tour
        :name="this.$route.name"
        :steps="tourSteps"
        :callbacks="finishCallbackOnly"
        :options="translatedLabels"
      ></v-tour>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import LanguageSelector from '@/components/LanguageSelector'
import EnforcePermissionsForCommunity from '@/mixins/EnforcePermissionsForCommunity'
import TourManager from '@/mixins/TourManager'
import ClickOutside from 'vue-click-outside'
import VueGravatar from 'vue-gravatar'

export default {
  name: 'admin',
  mixins: [EnforcePermissionsForCommunity, TourManager],
  components: {
    LanguageSelector,
    VueGravatar
  },
  data() {
    return {
      isUserDropdownActive: false,
      isMobileMenuOpen: false,
      isMobileUserDropdownOpen: false,
      isMobileCivilianMenuOpen: false,
      isMobilePoliceMenuOpen: false,
      isMobilePluginsMenuOpen: false,
      isMobileLegalMenuOpen: false,
      isCivilianMenuOpen: false,
      isPoliceMenuOpen: false,
      isPluginsMenuOpen: false,
      isLegalMenuOpen: false,
      tourSteps: [
        {
          target: '.v-step-sidebar',
          content: this.$t('tour.admin.sidebar'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-general-section',
          content: this.$t('tour.admin.general-section'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'admin-general' })
              resolve()
            })
        },
        {
          target: '.v-step-user-approval',
          content: this.$t('tour.admin.user-approval'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'admin-users' })
              resolve()
            })
        },
        {
          target: '.v-step-community-members',
          content: this.$t('tour.admin.community-members'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-access-codes-section',
          content: this.$t('tour.admin.access-codes-section'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'admin-access-codes' })
              resolve()
            })
        },
        {
          target: '.v-step-copy-access-code',
          content: this.$t('tour.admin.copy-access-code'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-access-code-permissions-preview',
          content: this.$t('tour.admin.access-code-permissions-preview'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-manage-access-code',
          content: this.$t('tour.admin.manage-access-code'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-create-access-code',
          content: this.$t('tour.admin.create-access-code'),
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '.v-step-status-code-section',
          content: this.$t('tour.admin.status-code-section'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'admin-status-codes' })
              resolve()
            })
        },
        {
          target: '.v-step-driver-license-section',
          content: this.$t('tour.admin.driver-license-section'),
          params: {
            highlight: true,
            enableScrolling: false
          },
          before: type =>
            new Promise((resolve, reject) => {
              this.$router.push({ name: 'admin-driver-licence' })
              resolve()
            })
        }
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(response => {
        this.$router.push({ name: 'login' })
      })
    }
  },
  watch: {
    $route(to, from) {
      this.isMobileMenuOpen = false
      this.isMobileUserDropdownOpen = false
      this.isUserDropdownActive = false
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
